<template>
	<div style="height: calc(100vh - 241px)">
		<v-layout class="mb-1">
			<v-row class="d-flex align-center">
				<v-select
					v-model="filter.month"
					class="listing-select pr-0"
					hide-details
					:disabled="pageLoading"
					:items="monthList"
					solo
					item-text="title"
					item-value="value"
					style="max-width: 175px !important; min-width: 175px !important"
					:menu-props="{ offsetY: true, bottom: true }"
					@change="getKeywords()"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content class="p-0">
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select>

				<v-select
					v-model="filter.year"
					class="listing-select year"
					hide-details
					:disabled="pageLoading"
					:items="yearList"
					solo
					item-text="title"
					item-value="value"
					style="max-width: 110px !important; min-width: 110px !important"
					:menu-props="{ offsetY: true, bottom: true }"
					@change="getKeywords()"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select>

				<v-text-field
					right
					class="ml-3"
					ref="search"
					v-model.trim="filter.search"
					autocomplete="off"
					hide-details
					placeholder="search"
					outlined
					@keydown.esc="onEsc"
					@keydown.enter="getKeywords()"
					style="max-width: 280px; min-width: 250px"
				>
					<template #prepend-inner>
						<v-icon color="grey" class="ml-1 mr-2"> mdi-magnify </v-icon>
					</template>
				</v-text-field>
				<v-btn
					depressed
					color="white"
					class="blue darken-4"
					tile
					text
					small
					style="height: 30px"
					v-on:click="getKeywords()"
				>
					<v-icon>mdi-magnify</v-icon>
				</v-btn>
				<v-btn
					depressed
					color="white"
					class="red darken-4 mx-2"
					tile
					text
					small
					style="height: 30px; min-width: 0px !important"
					v-on:click="resetSearch"
				>
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-row>
		</v-layout>
		<div style="max-height: calc(100vh - 297px); overflow-y: auto; overflow-x: hidden">
			<div class="seo-tabs">
				<v-simple-table
					width="100%"
					fixed-header
					class="detail-table normal-table table-head-sticky mb-5"
				>
					<thead>
						<tr>
							<th width="5%" class="p-2 light-blue-bg custome-table">#</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Analysis Code</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Date</th>
						</tr>
					</thead>
					<tbody v-if="kwExcel.length">
						<tr
							v-for="(row, index) in kwExcel"
							:class="['cursor-pointer', { 'grey lighten-3': row.id === activeRowId }]"
							@click="rowActive(row)"
							:key="row.name + '_' + index"
						>
							<td width="5%" class="p-2 border-top-light-grey">
								{{ index + 1 }}
							</td>
							<td width="20%" class="p-2 border-top-light-grey">
								<Chip :text="row.barcode" color="cyan" v-if="row.barcode"></Chip>
							</td>
							<td width="20%" class="p-2 border-top-light-grey">
								<span>{{ formatDateTime(row.added_at) }}</span>
							</td>
						</tr>
					</tbody>
					<tfoot v-else>
						<tr>
							<td :colspan="5">
								<p class="m-0 row-not-found text-center py-3">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There <span class="text-lowercase">are no file at the moment.</span>
								</p>
							</td>
						</tr>
					</tfoot>
				</v-simple-table>
			</div>
			<div class="table-detail">
				<v-simple-table width="100%" class="detail-table normal-table table-head-sticky">
					<thead>
						<tr>
							<th width="10%" class="p-2 light-blue-bg custome-table">#</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Keyword</th>
							<th width="20%" class="p-2 light-blue-bg custome-table">Avg Searches</th>
							<th width="25%" class="p-2 light-blue-bg custome-table">Suggested Bid</th>
							<th width="25%" class="p-2 light-blue-bg custome-table">Position On Start</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="pageLoading">
							<tr>
								<td colspan="7" width="100%" class="mx-20 my-40 text-center">
									<v-progress-linear color="orange darken-2" indeterminate height="6"></v-progress-linear>
								</td>
							</tr>
						</template>

						<template v-else-if="activeRowId != null && kwAnalysis && kwAnalysis.length > 0">
							<tr v-for="(row, index) in kwAnalysis" :key="index">
								<td width="10%" class="p-2 border-top-light-grey custome-table-td">
									{{ index + 1 }}
								</td>
								<td width="20%" class="p-2 border-top-light-grey custome-table-td">
									<ShowValue :object="row" object-key="keyword" label="keyword"></ShowValue>
								</td>
								<td width="10%" class="p-2 border-top-light-grey custome-table-td">
									<div class="d-flex">
										<v-chip
											v-if="row.msv_trafic"
											class="text-capitalize rounded-sm"
											color="cyan darken-4"
											outlined
											width="70"
										>
											{{ row.msv_trafic }}
										</v-chip>
									</div>
								</td>
								<td width="15%" class="p-2 border-top-light-grey custome-table-td">
									<Chip
										class="text-capitalize text-center"
										v-if="row.suggested_bid"
										:text="row.suggested_bid"
										color="green darken-4"
										text-color=""
										outlined
										width="80"
									></Chip>
								</td>
								<td width="20%" class="p-2 border-top-light-grey custome-table-td">
									<Chip
										class="text-capitalize"
										v-if="row.current_rank"
										:text="row.current_rank"
										:color="isNaN(+row.current_rank) ? 'red darken-4' : 'green darken-4'"
										text-color=""
										outlined
										:width="isNaN(+row.current_rank) ? '100' : '45'"
									></Chip>
								</td>
							</tr>
						</template>

						<template v-else>
							<tr>
								<td :colspan="7">
									<p class="m-0 row-not-found text-center py-3">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There <span class="text-lowercase">are no file at the moment.</span>
									</p>
								</td>
							</tr>
						</template>
					</tbody>
				</v-simple-table>
			</div>
		</div>
	</div>
</template>
<style>
.custome-table {
	background-color: #f3f6f9;
	border-bottom: thin solid rgba(0, 0, 0, 0.12);
	border-right: 1px solid #e2e5ec !important;
}
.custome-table-td {
	border-right: 1px solid #e2e5ec !important;
}
.border-top-light-grey {
	border-top: solid 1px #e2e5ec !important;
	/*  border-bottom: solid 1px #bcc0c9 !important; */
}
:is(.normal-table) thead tr th,
tbody tr td {
	font-size: 13px !important;
	color: rgba(0, 0, 0, 0.87) !important;
}

.normal-table thead tr th {
	font-weight: 500 !important;
}
.normal-table tbody tr td {
	font-weight: 400 !important;
}
.normal-table tbody tr:nth-child(odd) {
	background-color: #e7f4ff;
}

.seo-tabs .v-data-table__wrapper {
	max-height: 333px;
	overflow-y: auto !important;
}
</style>

<script>
import MainMixin from "@/core/mixins/main.mixin";
import ApiService from "@/core/services/api.service";
import ShowValue from "@/view/components/ShowValue";
import JwtService from "@/core/services/jwt.service";
import Chip from "@/view/components/Chip";
import { mapGetters } from "vuex";
import { debounce } from "lodash";
export default {
	name: "Seo-Analysis-listing",
	title: "Listing Invoice",
	mixins: [MainMixin],
	data() {
		return {
			filter: {
				month: "",
				year: new Date().getFullYear().toString(),
				search: null,
			},
			month_menu: false,
			year_menu: false,
			yearList: [],
			monthList: [
				{ index: 0, title: "All MONTH", value: "" },
				{ index: 1, title: "January", value: "01" },
				{ index: 2, title: "February", value: "02" },
				{ index: 3, title: "March", value: "03" },
				{ index: 4, title: "April", value: "04" },
				{ index: 5, title: "May", value: "05" },
				{ index: 6, title: "June", value: "06" },
				{ index: 7, title: "July", value: "07" },
				{ index: 8, title: "August", value: "08" },
				{ index: 9, title: "September", value: "09" },
				{ index: 10, title: "October", value: "10" },
				{ index: 11, title: "November", value: "11" },
				{ index: 12, title: "December", value: "12" },
			],

			pageTitle: "Seo-Analysis",
			pageBreadcrumbs: [{ text: "Seo-Analysis", disabled: true }],
			activeRowId: 1,
			endpoint: "seo-analysis",
			defaultFilter: {},
			count: {},
			status: "all",
			pageLoading: false,
			importDialog: false,
			kwAnalysis: [],
			kwExcel: [],
			page: 1,
			showing_string: null,
			totalRows: 0,
			rowsOffset: 0,
			totalPages: 0,
			totalSelected: 0,
			paginationPageSize: 0,
			deleteDialog: false,
			deleteURL: null,
			per_page: 5,
			total_page: null,
			id: null,
			seoId: null,
			csvDownloadLink: null,
		};
	},
	props: {
		projectId: {
			type: Number,
			default: 0,
		},
		fileName: {
			type: String,
			default: "",
		},
	},
	watch: {
		"filter.search": {
			handler: function () {
				let run = debounce(function () {
					this.getKeywords();
				}, 1000);
				run();
			},
		},
	},
	methods: {
		rowActive(row) {
			if (row.id != this.activeRowId) {
				this.activeRowId = row.id;
				this.csvDownloadLink = row.url;
				this.getKeywords();
			}
		},
		statusColor(value) {
			return +value >= 200 && +value < 300
				? "green"
				: +value >= 300 && +value < 400
				? "orange"
				: "red";
		},
		exportCSV(url = null) {
			ApiService.setHeader();
			let downloadURL = url
				? url
				: this.activeRowId
				? process.env.VUE_APP_API_URL +
				  `seo/${this.seoId}/project/${this.projectId}/url-mapping-export?url_map_id=${this.activeRowId}`
				: "";

			console.log({ downloadURL });
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			downloadURL.searchParams.append("token", token);
			const project_name = this.fileName;

			const customName =
				project_name +
				" - keyword analysis (" +
				this.formatDateTimeRawWithoutTime(new Date()) +
				").csv";

			fetch(downloadURL)
				.then((response) => response.blob())
				.then((blob) => {
					const blobURL = URL.createObjectURL(blob);
					const anchor = document.createElement("a");
					anchor.href = blobURL;
					anchor.setAttribute("download", customName);
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
					URL.revokeObjectURL(blobURL);
				});
		},
		getKeywords(activeId = this.activeRowId.toString()) {
			this.pageLoading = true;
			const payload = {
				keyword_analysis_id: activeId,
				sort: "asc",
				status: null,
				per_page: 10000,

				...this.filter,
			};
			ApiService.query(`seo/${this.seoId}/project/${this.projectId}/seo-keyword-analysis`, payload)
				.then(({ data }) => {
					this.kwAnalysis = data;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getkwExcel() {
			this.pageLoading = true;
			ApiService.query(`seo/${this.seoId}/project/${this.projectId}/excel-seo-keyword-analysis`)
				.then(({ data, status }) => {
					this.kwExcel = data || [];
					this.csvDownloadLink = (data && data[0] && data[0].url) || null;
					if (status && data.length > 0) {
						let id = data && data.length > 0 ? data[0].id : null;
						this.activeRowId = id;
						this.getKeywords(id);
					} else {
						this.activeRowId = null;
					}
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},

		onEsc() {
			this.resetSearch();
		},
		resetSearch() {
			const _this = this;
			_this.$nextTick(() => {
				_this.filter.search = null;
				this.getKeywords();
			});
		},
		getYears() {
			let current_year = +new Date().toISOString().slice(0, 4);
			let _yearList = [];
			while (_yearList.length < 10) {
				let obj = {
					title: current_year.toString(),
					index: _yearList.length,
					value: current_year.toString(),
				};
				_yearList.push(obj);
				current_year = current_year - 1;
			}
			this.yearList = [..._yearList];
		},
	},
	components: {
		ShowValue,
		Chip,
	},
	mounted() {
		if (this.$route.params.id) {
			this.seoId = this.$route.params.id;
			this.getkwExcel();
		}
		this.getYears();
	},
	computed: {
		...mapGetters(["selected", "tbody", "currentPage", "totalPage", "showingString"]),
	},
};
</script>
