<template>
	<div>
		<!-- <v-sheet class="recurring-main-listing"> -->
		<v-layout class="mb-1 align-center">
			<div class="d-flex align-center w-100">
				<v-select
					v-model="monthFilter"
					class="listing-select pr-0"
					hide-details
					:disabled="pageLoading"
					:items="monthList"
					solo
					item-text="title"
					style="max-width: 175px !important; min-width: 175px !important"
					:menu-props="{ offsetY: true, bottom: true }"
					@change="getSeoRankingReport"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content class="pl-0">
							<v-list-item-title v-text="item.title"></v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select>
				<v-select
					v-model="yearFilter"
					class="listing-select year"
					hide-details
					:disabled="pageLoading"
					:items="yearList"
					solo
					item-text="year"
					style="max-width: 110px !important; min-width: 110px !important"
					:menu-props="{ offsetY: true, bottom: true }"
					@change="getSeoRankingReport()"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.year"></v-list-item-title>
						</v-list-item-content>
					</template>
				</v-select>
			</div>
			<template v-if="usersType == 'seo' || getPermission('ranking_report:create')">
				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-btn
							depressed
							:disabled="pageLoading"
							tile
							color="green darken-2"
							class="mr-2"
							dark
							x-small
							style="height: 26px !important"
							v-on="on"
							v-bind="attrs"
							:href="generate_excel_url"
							download
						>
							<v-icon> mdi-table-arrow-down </v-icon>
						</v-btn>
					</template>
					<span>Export excel</span>
				</v-tooltip>
				<v-tooltip top>
					<template #activator="{ on, attrs }">
						<v-btn
							depressed
							:disabled="pageLoading"
							tile
							color="blue darken-2"
							class="text-white mr-2"
							v-on="on"
							v-bind="attrs"
							href="/media/sample-files/Sample_ranking_reports.xlsx"
							download
						>
							<v-icon left> mdi-download </v-icon>
							Sample CSV
						</v-btn>
					</template>
					<span>Download Sample CSV File</span>
				</v-tooltip>
				<v-tooltip top>
					<template #activator="{ on, attrs }"
						><v-btn
							depressed
							:disabled="pageLoading"
							tile
							color="blue darken-4"
							class="text-white"
							v-on:click="importDialog = true"
							v-on="on"
							v-bind="attrs"
						>
							<v-icon left> mdi-plus </v-icon>
							Upload File
						</v-btn>
					</template>
					<span>Upload CSV File</span>
				</v-tooltip>
			</template>
		</v-layout>
		<div class="d-flex justify-space-between mb-4" style="width: 100%">
			<div style="width: 100%" class="detail-card bg-white">
				<v-col md="12" style="overflow: scroll" class="pt-0 px-0 bt-table listing-table">
					<v-simple-table width="100%" class="bt-table detail-table normal-table table-head-sticky mb-5">
						<thead>
							<tr>
								<th style="width: 140px" class="simple-table-th text-center">Action</th>
								<th width="15%" class="simple-table-th text-center">PDF</th>
								<th width="15%" class="simple-table-th text-center">Ranking Code</th>
								<th width="20%" class="simple-table-th">Date of Report</th>
								<th class="simple-table-th">Generated By</th>
							</tr>
						</thead>
						<tbody v-if="reports_list.length">
							<tr class="task_row" v-for="(row, index) in reports_list" :key="'$_reporting _' + index">
								<td class="td_column cursor-default simple-table-td border-top-light-grey">
									<div class="d-flex align-center justify-space-around">
										<template
											v-if="
												getPermission('ranking_report:delete') &&
												AllowAnyOforGetRoleNType([
													'master',
													'admin',
													'manager',
													'super_admin',
													'team_lead',
													'ateam_lead',
												])
											"
										>
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<v-icon
														v-bind="attrs"
														v-on="on"
														v-on:click.stop.prevent="deleteConfirm(row)"
														size="25"
														class="cursor-pointer"
														color="red"
														>mdi-delete</v-icon
													>
												</template>
												<span>Delete</span>
											</v-tooltip>
										</template>

										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<div v-bind="attrs" v-on="on">
													<v-icon
														size="30"
														class="cursor-pointer"
														color="green"
														@click="downloadFile(row.excel_url, 'csv')"
														>mdi-file-excel</v-icon
													>
												</div>
											</template>
											<span>Excel Download</span>
										</v-tooltip>
									</div>
								</td>

								<td class="td_column cursor-default text-center simple-table-td border-top-light-grey py-3">
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<div v-bind="attrs" v-on="on">
												<v-icon
													class="cursor-pointer"
													color="red"
													size="30"
													@click="downloadFile(row.pdf_url, 'pdf')"
													>mdi-file-pdf</v-icon
												>
											</div>
										</template>
										<span>PDF Download</span>
									</v-tooltip>
								</td>
								<td class="td_column cursor-default text-center simple-table-td border-top-light-grey">
									<v-chip outlined class="rounded font-weight-bold" color="cyan">
										{{ row.barcode }}
									</v-chip>
								</td>
								<td
									style="padding: 0; white-space: break-spaces"
									class="td_column cursor-default simple-table-td border-top-light-grey py-3"
								>
									<div class="d-flex align-center">
										<v-icon size="20" class="mr-1">calendar_month</v-icon>
										<div class="mt-1 font-weight-medium">{{ formatDate(row.to_date) }}</div>
									</div>
								</td>

								<td class="td_column cursor-default simple-table-td border-top-light-grey py-3">
									<div class="d-flex align-center">
										<v-icon size="22" class="mr-1">person</v-icon>
										<v-chip outlined class="rounded font-weight-bold" color="cyan">
											{{ row.added_by?.display_name }}
										</v-chip>
									</div>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<td colspan="5" class="simple-table-th text-center">
								<p class="m-0 row-not-found text-center">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no data at the moment.
								</p>
							</td>
						</tfoot>
					</v-simple-table>
				</v-col>
				<DeleteTemplate
					type="Ranking Report"
					v-if="getPermission('ranking_report:delete')"
					:delete-text="deleteBarcode"
					v-on:success="deleteReport()"
					v-on:close="deleteDialog = false"
					:delete-dialog="deleteDialog"
					:delete-url="deleteURL"
					delete-note="barcode"
				>
				</DeleteTemplate>

				<ImportSeoRankingReportDialog
					v-if="importDialog && getPermission('ranking_report:create')"
					endpoint="seo-ranking-report/import"
					title="Ranking Report"
					:import-dialog.sync="importDialog"
					v-on:close="importDialog = false"
					v-on:refress="getSeoRankingReport()"
					:project-id="projectId"
					:seo-id="seoId"
					sample-file="/media/sample-files/Sample_ranking_reports.xlsx"
				></ImportSeoRankingReportDialog>
			</div>
		</div>
		<!-- </v-sheet> -->
	</div>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
;
<script>
// import { find } from "lodash";
import { QUERY } from "@/core/services/store/request.module";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ImportSeoRankingReportDialog from "@/view/components/ImportSeoRankingReportDialog.vue";
import HeightMixin from "@/core/mixins/height.mixin";
import MainMixin from "@/core/mixins/main.mixin";
import JwtService from "@/core/services/jwt.service";
import ApiService from "@/core/services/api.service";
import "@/core/event-bus/click-outside";
export default {
	name: "Domain-Overview-Tab",
	mixins: [MainMixin, HeightMixin],
	components: {
		DeleteTemplate,
		ImportSeoRankingReportDialog,
	},
	props: {
		seoId: {
			type: [String, Number],
			default: 0,
		},
		projectId: {
			type: [String, Number],
			default: 0,
		},
		fileName: {
			type: String,
			default: "",
		},
		usersType: {
			type: String,
		},
	},
	data() {
		return {
			skipBottomHeight: 56,
			monthFilter: "all",
			yearFilter: +new Date().toISOString().slice(0, 4),
			pageLoading: false,
			reports_list: [],
			deleteURL: null,
			deleteBarcode: null,
			deleteDialog: false,
			createBackLinkDialog: false,
			yearList: [],
			generate_excel_url: null,
			monthList: [
				{ title: "All Month", value: "all" },
				{ title: "January", value: "01" },
				{ title: "February", value: "02" },
				{ title: "March", value: "03" },
				{ title: "April", value: "04" },
				{ title: "May", value: "05" },
				{ title: "June", value: "06" },
				{ title: "July", value: "07" },
				{ title: "August", value: "08" },
				{ title: "September", value: "09" },
				{ title: "October", value: "10" },
				{ title: "November", value: "11" },
				{ title: "December", value: "12" },
			],
			importDialog: false,
		};
	},
	computed: {},
	watch: {
		monthFilter: {
			handler: function (val) {
				console.log({ val });
			},
			deep: true,
		},
	},
	methods: {
		deleteReport() {
			this.deleteDialog = false;
			this.getSeoRankingReport();
		},
		deleteConfirm(row) {
			this.deleteURL = `seo/project/ranking-report/${row.id}`;
			this.deleteBarcode = row.barcode;
			this.deleteDialog = true;
		},
		downloadFile(url, type) {
			console.log({ url });
			// window.open(url, "_blank");

			ApiService.setHeader();
			let downloadURL = url;
			downloadURL = new URL(downloadURL);
			const token = JwtService.getToken();
			downloadURL.searchParams.append("token", token);

			// const project_name = this.projectTitle;

			const customName =
				this.fileName +
				" - keyword_ranking (" +
				this.formatDateTimeRawWithoutTime(new Date()) +
				")." +
				type;

			fetch(downloadURL)
				.then((response) => response.blob())
				.then((blob) => {
					const blobURL = URL.createObjectURL(blob);
					const anchor = document.createElement("a");
					anchor.href = blobURL;
					anchor.setAttribute("download", customName);
					document.body.appendChild(anchor);
					anchor.click();
					document.body.removeChild(anchor);
					URL.revokeObjectURL(blobURL);
				});
		},
		getSeoRankingReport() {
			const filter = {
				month: this.monthFilter == "all" ? null : this.monthFilter,
				year: this.yearFilter,
			};
			this.$store
				.dispatch(QUERY, {
					url: `seo/${this.seoId}/project/${this.projectId}/seo-ranking-report`,
					data: filter,
				})
				.then((data) => {
					if (data && Array.isArray(data)) {
						this.reports_list = data;
						this.generate_excel_url = data[0].generated_excel;
					}
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		getYears() {
			let current_year = +new Date().toISOString().slice(0, 4);
			let _yearList = [];
			while (_yearList.length < 10) {
				let obj = { year: current_year, index: _yearList.length, value: current_year };
				_yearList.push(obj);
				current_year = current_year - 1;
			}
			this.yearList = [..._yearList];
		},
	},
	mounted() {
		this.getYears();
		this.getSeoRankingReport();
	},
};
</script>

<style>
.listing-select .v-select__selection--comma {
	margin-top: 0px;
	margin-bottom: 0px;
}

:is(.normal-table) thead tr th,
tbody tr td {
	font-size: 13px !important;
	color: rgba(0, 0, 0, 0.87) !important;
}

.normal-table thead tr th {
	font-weight: 500 !important;
}
.normal-table tbody tr td {
	font-weight: 400 !important;
}
.normal-table tbody tr:nth-child(odd) {
	background-color: #e7f4ff;
}
</style>
