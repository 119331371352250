<template>
	<Dialog :dialog.sync="importDialog" @close="$emit('close', true)">
		<template v-slot:title>Import {{ title }} </template>
		<template v-slot:body>
			<v-row>
				<v-col md="12">
					<p class="btx-p">
						Download a <a :href="sampleFile" download>sample file</a> and compare it to your import file
						to ensure you have the perfect file for the import.
					</p>
				</v-col>
				<v-col md="4">
					<label for="xls-csv-file" class="required btx-label">Upload file</label>
				</v-col>
				<v-col md="8">
					<v-file-input
						id="xls-csv-file"
						v-model="file"
						class="mt-0 pt-0"
						placeholder="Choose File"
						prepend-icon="mdi-paperclip"
					>
						<template v-slot:selection="{ text }">
							<v-chip small label color="primary">
								{{ text }}
							</v-chip>
						</template>
					</v-file-input>
					<p class="btx-small-p">Maximum File Size: 5 MB | File Format: CSV or XLSX</p>
				</v-col>
				<v-col md="12">
					<template v-for="(data, index) in responseMsg">
						<p style="color: red" :key="index">
							{{ data }}
						</p>
					</template>
				</v-col>
			</v-row>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				depressed
				color="blue darken-4"
				tile
				v-on:click="importDocuments"
				:disabled="pageLoading"
				:loading="pageLoading"
			>
				Import
			</v-btn>
			<v-btn depressed tile v-on:click="$emit('close', true)"> Close </v-btn>
		</template>
	</Dialog>
</template>
<script>
import Dialog from "@/view/components/Dialog.vue";
import ApiService from "@/core/services/api.service"; /* 
import { GET_LISTING } from "@/core/services/store/listing.module"; */
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";

export default {
	name: "import-dialog",

	data() {
		return {
			pageTitle: "Assets",
			duplicate_handling: "skip",
			pageLoading: false,
			file: null,
			responseMsg: [],
		};
	},
	props: {
		importDialog: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: null,
		},
		projectId: {
			type: Number,
			default: 0,
		},
		seoId: {
			type: Number,
			default: 0,
		},
		endpoint: {
			type: String,
			default: null,
		},
		sampleFile: {
			type: String,
			default: "",
		},
	},
	methods: {
		importDocuments() {
			if (this.file == null) {
				this.$store.commit(SET_ERROR, [{ model: true, message: "File is required." }]);
				return false;
			}
			this.pageLoading = true;
			const fileType = `${this.file.name.split(".").pop()}`;
			ApiService.setHeader();
			const formData = new FormData();
			formData.append("excel_file", this.file);
			formData.append("project_id", this.projectId ? this.projectId : 0);
			formData.append("seo_id", this.seoId ? this.seoId : 0);
			formData.append("extension", fileType);
			ApiService.upload(this.endpoint, formData)
				.then(({ data }) => {
					this.responseMsg = data.messages;
					this.$store.commit(SET_MESSAGE, [{ model: true, message: "Document uploaded successfully" }]);
					this.$emit("close", true);
					this.$emit("refress", true);
				})
				.catch((error) => {
					console.log(error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	components: {
		Dialog,
	},
};
</script>
